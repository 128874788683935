// extracted by mini-css-extract-plugin
export var container = "index-module--container--UWizs";
export var contentArea = "index-module--content-area--ZWtck";
export var destination = "index-module--destination--Hb77D";
export var destinationAnchor = "index-module--destination-anchor--nnEW5";
export var destinationLabel = "index-module--destination-label--DRGxZ";
export var destinationLabelWrapper = "index-module--destination-label-wrapper--3rXmT";
export var destinationMailto = "index-module--destination-mailto--bZz9N";
export var destinationWrapper = "index-module--destination-wrapper--FOI+3";
export var heading = "index-module--heading--vtM9w";
export var headingMain = "index-module--heading-main--F4JCS";
export var headingWrapper = "index-module--heading-wrapper--VnM-C";
export var lead = "index-module--lead--ZxjL7";
export var leadWrapper = "index-module--lead-wrapper--diY4X";
export var listWithEtc = "index-module--list-with-etc--m2kll";
export var listWithEtcEtc = "index-module--list-with-etc-etc--kyOt5";
export var listWithEtcEtcWrapper = "index-module--list-with-etc-etc-wrapper--q7buv";
export var listWithEtcList = "index-module--list-with-etc-list--fFyHf";
export var listWithEtcListItem = "index-module--list-with-etc-list-item--Igwuh";
export var listWithEtcListWrapper = "index-module--list-with-etc-list-wrapper--fIOv9";
export var listWithEtcWrapper = "index-module--list-with-etc-wrapper--oxYRX";
export var main = "index-module--main--7Q9ON";
export var paragraph = "index-module--paragraph--6pe5L";
export var paragraphAddition = "index-module--paragraph-addition--cTlgq";
export var paragraphWrapper = "index-module--paragraph-wrapper--8kKeH";