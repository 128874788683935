import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import * as styles from './index.module.css';

const ContactTemplate = ({ pageContext }) => {
  const { pagePath } = pageContext;

  return (
    <div className="root">
      <SEO path={pagePath} title="お問い合わせ" />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <section>
              <div className={styles['container']}>
                <div className={styles['contentArea']}>
                  <div className={styles['headingWrapper']}>
                    <h1 className={styles['heading']}>
                      <span className={styles['headingMain']}>
                        お問い合わせ
                      </span>
                    </h1>
                  </div>

                  <div className={styles['leadWrapper']}>
                    <p className={styles['lead']}>
                      TORCH編集部は、さらなる記事コンテンツ充実のため、皆さんからのお便りをお待ちしております。
                    </p>
                  </div>

                  <div className={styles['destinationWrapper']}>
                    <div className={styles['destination']}>
                      <InlineBlockWrapper
                        className={styles['destinationLabelWrapper']}
                      >
                        <span className={styles['destinationLabel']}>
                          TORCH編集部
                        </span>
                      </InlineBlockWrapper>
                      <InlineBlockWrapper>
                        <span className={styles['destinationMailto']}>
                          <a
                            href="mailto:info@torch-sports.jp"
                            className={styles['destinationAnchor']}
                          >
                            info@torch-sports.jp
                          </a>
                        </span>
                      </InlineBlockWrapper>
                    </div>
                  </div>

                  <div className={styles['listWithEtcWrapper']}>
                    <div className={styles['listWithEtc']}>
                      <div className={styles['listWithEtcListWrapper']}>
                        <ul className={styles['listWithEtcList']}>
                          <li className={styles['listWithEtcListItem']}>
                            記事へのご意見・ご感想
                          </li>
                          <li className={styles['listWithEtcListItem']}>
                            取材のご希望
                          </li>
                          <li className={styles['listWithEtcListItem']}>
                            取り上げてほしいテーマ
                          </li>
                          <li className={styles['listWithEtcListItem']}>
                            ライター希望
                          </li>
                        </ul>
                      </div>
                      <InlineBlockWrapper
                        className={styles['listWithEtcEtcWrapper']}
                      >
                        <span className={styles['listWithEtcEtc']}>など</span>
                      </InlineBlockWrapper>
                    </div>
                  </div>

                  <div className={styles['paragraphWrapper']}>
                    <p className={styles['paragraph']}>
                      さまざまなお問い合わせをお待ちしております。
                    </p>
                    <p className={styles['paragraphAddition']}>
                      ※ご意見いただきました内容を編集部で検討し、順次ご回答申し上げます。コンテンツへの反映をお約束するものではございません。あらかじめご了承ください。
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </div>
  );
};

export default ContactTemplate;
